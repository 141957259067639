import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";

import Layout from "../components/Layout";
import HTMLContent from "../components/Content";
import "../styles/activities.scss";

export const ActivitiesPageTemplate = props => {
    const { page } = props;
    return (
        <article className="activities">
            <div className="activities-container container">
                <section className="activities-header">
                    <div className="activities-titleWrapper">
                        <h1 className="activities-title">{page.frontmatter.title}</h1>
                    </div>
                </section>
                <section className="activities-content section">
                    {page.bodyIsMarkdown ? (
                        <ReactMarkdown className="activities-description" source={page.html} />
                    ) : (
                            <HTMLContent className="activities-description" content={page.html} />
                        )}
                </section>
            </div>
        </article>
    );
};

const ActivitiesPage = ({ data }) => {
    const { markdownRemark: page, footerData, navbarData } = data;
    const {
        frontmatter: {
            seo: { title: seoTitle, description: seoDescription, browserTitle },
        },
    } = page;

    return (
        <Layout footerData={footerData} navbarData={navbarData}>
            <Helmet>
                <meta name="title" content={seoTitle} />
                <meta name="description" content={seoDescription} />
                <title>{browserTitle}</title>
            </Helmet>
            <ActivitiesPageTemplate page={{ ...page, bodyIsMarkdown: false }} />
        </Layout>
    );
};

ActivitiesPage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ActivitiesPage;

export const ActivitiesPageQuery = graphql`
  query ActivitiesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title        
        seo {
          browserTitle
          title
          description
        }
      }
    }
    ...LayoutFragment
  }
`;
